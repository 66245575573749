<template>
  <LSection>
    <template #body>
      <div class="flex flex-col items-center justify-center mb-12 w-full">
        <div class="w-full">
          <!--          <TabNavigation :tabs="tabs" />-->
          <router-view name="aboutTab"></router-view>
        </div>
      </div>
    </template>
  </LSection>
</template>

<script>
import { ref } from "vue";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "About",
  components: { LSection },
  setup() {
    const tabs = ref([
      { name: "About Us", to: { name: "aboutUs" }, routeName: "aboutUs" },
      { name: "Medals", to: { name: "aboutMedals" }, routeName: "aboutMedals" },
      {
        name: "Principles",
        to: { name: "aboutPrinciples" },
        routeName: "aboutPrinciples",
      },
    ]);
    return {
      tabs,
    };
  },
};
</script>
